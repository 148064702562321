@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: var(--font-body);
    }

    h1, h2, h3 {
        @apply font-header;
        @apply [text-wrap:balance];
    }

    h2, h3 {
        @apply text-2xl;
    }

    picture {
        @apply block;
        @apply overflow-hidden;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

:root {

    --color-base: #ffffff;

    --color-primary: #111828;
    --color-primary-light: #6B7281;
    --color-primary-lighter: #E5E7FB;

    --color-secondary: #6B21A7;
    --color-secondary-light: #9333EB;
    --color-secondary-lighter: #C084FD;

    --color-tertiary: #8193ab;
    --color-tertiary-light: #d7dbdf;
    --color-tertiary-lighter: #f6f8f8;

    /* Theme bulleted lists */
    --color-bullets: var(--color-secondary);

    /* Theme for main navigation */
    --nav-primary: var(--color-base);
    --nav-secondary: var(--color-primary);
    --nav-tertiary: var(--color-tertiary);

    /* Theme for footer section */
    --footer-primary: var(--color-primary);
    --footer-secondary: var(--color-secondary);
    --footer-tertiary: var(--color-tertiary-lighter);

    /* Theme for Tag component */
    --tag-primary: var(--color-secondary);
    --tag-secondary: var(--color-tertiary);

    /* Theming for Card component */
    --card-base: var(--color-tertiary-lighter);
    --card-secondary: var(--color-secondary);
    --card-tertiary: var(--color-primary);
    --card-border-color: var(--color-primary-lighter);
    --card-border-width: 3px;

    /* Theming for Audio component */
    --audio-theme-color: var(--color-secondary);
    --audio-background-color: var(--color-tertiary-lighter);
    --audio-bar-color: var(--color-primary-light);
    --audio-time-color: var(--color-primary);
    --audio-border-color: transparent;
}


/******************************************************************** 
 * CSS Variable Overrides for dark theme
 ********************************************************************/
.dark {
    --color-base: #111827;
    --color-primary: #E5E7EB;
    --color-secondary: var(--color-secondary-lighter);
    --color-tertiary: #383a42;


    --footer-tertiary: var(--color-tertiary);

    --card-base: var(--color-tertiary);
    --card-border-color: var(--color-tertiary);

    --audio-border-color: var(--color-primary);
    --audio-background-color: var(--color-tertiary);
}


/******************************************************************** 
 * Base themes selectors
 ********************************************************************/

body {
    color: var(--color-primary);
}

:focus-visible {
    outline: 2px solid var(--color-secondary);
    outline-offset: 3px;
}

a {
    display: inline-block;
}

/******************************************************************** 
 *Theme override for Prose (tailwind/typography) plugin
 ********************************************************************/
.prose {
    --tw-prose-body: var(--color-primary);
    --tw-prose-headings: var(--color-primary);
    --tw-prose-bold: var(--color-primary);
    --tw-prose-quotes: var(--color-primary);
    --tw-prose-code: var(--color-primary);
    --tw-prose-bullets: var(--color-secondary);
    --tw-prose-links: var(--color-secondary);
}


/******************************************************************** 
 * Theme override for Audio component
 ********************************************************************/
.rhap_progress-filled,
.rhap_volume-indicator,
.rhap_progress-indicator,
.rhap_volume-filled {
    background-color: var(--audio-theme-color) !important;
}

.rhap_repeat-button,
.rhap_main-controls-button,
.rhap_volume-button {
    color: var(--audio-theme-color) !important;
}

.rhap_container {
    border-width: 1px;
    border-style: solid;
    border-color: var(--audio-border-color);
    background-color: var(--audio-background-color) !important;
}

.rhap_progress-bar,
.rhap_download-progress,
.rhap_volume-bar {
    background-color: var(--audio-bar-color) !important;
}

.rhap_time {
    color: var(--audio-time-color) !important;
}